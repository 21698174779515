/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '../theme'
import { Container } from './Container'

export const MainSection = ({ children, ...otherProps }) => {
  return <MainSectionWrapper {...otherProps}>{children}</MainSectionWrapper>
}

export const DefaultMainContent = ({ children }) => {
  return (
    <Container>
      <DefaultMainContentWrapper>{children}</DefaultMainContentWrapper>
    </Container>
  )
}

const DefaultMainContentWrapper = styled.div`
  padding: 30px 0 30px 120px;

  ${breakpoint.tablet`
        padding: 30px 0;
    `}
`

const MainSectionWrapper = styled.div`
  background: radial-gradient(56.19% 168.11% at 28.98% -60.9%, #0496c8 0%, #023059 100%);
`
