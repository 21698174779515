import React from 'react'
// import PropTypes from 'prop-types'
import classes from './News.module.scss'

const News = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.section}>
        <h1>НОВОСТИ</h1>
      </div>
    </div>
  )
}

export default News
