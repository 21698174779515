/* eslint-disable camelcase */
/* eslint-disable max-len */
import pairui from './assets/logos/dist/pairui/pairui.png'
import pairui_1 from './assets/logos/dist/pairui/product-1.png'
import pairui_2 from './assets/logos/dist/pairui/product-2.png'
import pairui_3 from './assets/logos/dist/pairui/product-3.png'
import pairui_4 from './assets/logos/dist/pairui/product-4.png'
import locosys from './assets/logos/dist/locosys/locosys.png'
import locosys_1 from './assets/logos/dist/locosys/product-1.png'
import locosys_2 from './assets/logos/dist/locosys/product-2.png'
import locosys_3 from './assets/logos/dist/locosys/product-3.png'
import locosys_4 from './assets/logos/dist/locosys/product-4.png'
import yetnorson from './assets/logos/dist/yetnorson/yetnorson.png'
import yetnorson_1 from './assets/logos/dist/yetnorson/product-1.png'
import yetnorson_2 from './assets/logos/dist/yetnorson/product-2.png'
import yetnorson_3 from './assets/logos/dist/yetnorson/product-3.jpg'
import yetnorson_4 from './assets/logos/dist/yetnorson/product-4.png'
import feasycom from './assets/logos/dist/feasycom/feasycom.png'
import feasycom_1 from './assets/logos/dist/feasycom/product-1.png'
import feasycom_2 from './assets/logos/dist/feasycom/product-2.png'
import feasycom_3 from './assets/logos/dist/feasycom/product-3.png'
import feasycom_4 from './assets/logos/dist/feasycom/product-4.png'
import neoway from './assets/logos/dist/neoway/neoway.png'
import neoway_1 from './assets/logos/dist/neoway/product-1.png'
import neoway_2 from './assets/logos/dist/neoway/product-2.png'
import neoway_3 from './assets/logos/dist/neoway/product-3.png'
import neoway_4 from './assets/logos/dist/neoway/product-4.png'
import prt from './assets/logos/dist/prt/prt.png'
import prt_1 from './assets/logos/dist/prt/product-1.jpg'
import prt_2 from './assets/logos/dist/prt/product-2.jpg'
import prt_3 from './assets/logos/dist/prt/product-3.png'
import prt_4 from './assets/logos/dist/prt/product-4.jpg'
import cjtouch from './assets/logos/dist/cjtouch/cjtouch.png'
import cjtouch_1 from './assets/logos/dist/cjtouch/product-1.png'
import cjtouch_2 from './assets/logos/dist/cjtouch/product-2.png'
import cjtouch_3 from './assets/logos/dist/cjtouch/product-3.png'
import cjtouch_4 from './assets/logos/dist/cjtouch/product-4.png'
import hengstar from './assets/logos/dist/hengstar/hengstar.png'
import hengstar_1 from './assets/logos/dist/hengstar/product-1.png'
import hengstar_2 from './assets/logos/dist/hengstar/product-2.png'
import hengstar_3 from './assets/logos/dist/hengstar/product-3.png'
import hengstar_4 from './assets/logos/dist/hengstar/product-4.png'
import kingspec from './assets/logos/dist/kingspec/kingspec.png'
import kingspec_1 from './assets/logos/dist/kingspec/product-1.png'
import kingspec_2 from './assets/logos/dist/kingspec/product-2.png'
import kingspec_3 from './assets/logos/dist/kingspec/product-3.png'
import kingspec_4 from './assets/logos/dist/kingspec/product-4.png'
import agrade from './assets/logos/dist/agrade/agrade.png'
import agrade_1 from './assets/logos/dist/agrade/product-1.png'
import agrade_2 from './assets/logos/dist/agrade/product-2.png'
import agrade_3 from './assets/logos/dist/agrade/product-3.png'
import agrade_4 from './assets/logos/dist/agrade/product-4.png'
import taixing from './assets/logos/dist/taixing/taixing.png'
import taixing_1 from './assets/logos/dist/taixing/product-1.png'
import taixing_2 from './assets/logos/dist/taixing/product-2.png'
import taixing_3 from './assets/logos/dist/taixing/product-3.png'
import taixing_4 from './assets/logos/dist/taixing/product-4.png'
import skyMems from './assets/logos/dist/skymems/skymems.png'
import skyMems_1 from './assets/logos/dist/skymems/product-1.png'
import skyMems_2 from './assets/logos/dist/skymems/product-2.png'
import skyMems_3 from './assets/logos/dist/skymems/product-3.png'
import skyMems_4 from './assets/logos/dist/skymems/product-4.png'

const PairuCompany = {
  id: 'pairui',
  name: 'Pairui',
  title: 'Источники питания',
  logo: pairui,
  description: [
    'Группа компаний PaiRui - вертикально интегрированная холдинг, фокусирующийся на разработке и производстве источников питания. На собственных производственных мощностях PaiRui производит продукцию от источников питания до моточных изделий, магнитных сердечников и пластиковых деталей. Это позволяет снизить затраты и обеспечить гибкость производства. Как результат, PaiRui предлагает источники питания по очень конкурентноспособным ценам и создает уникальные продукты, удовлетворяющие самым высоким требованиям. Более 30 лет мы постоянно развиваемся, с целью стать Вашим надежным партнером в области питания. Предоставьте PaiRui возможность продемонстрировать Вам простоту использования нашей продукции «Powerfully Easy».',
  ],

  products: [
    {
      name: 'DC/DC Power',
      imageUrl: pairui_1,
      description: '',
    },
    {
      name: 'LED Drivers',
      imageUrl: pairui_2,
      description: '',
    },
    {
      name: 'AC/DC Power',
      imageUrl: pairui_3,
      description: '',
    },
    {
      name: 'Преобразователи на din-рейку',
      imageUrl: pairui_4,
      description: '',
    },
  ],
}

const LocosysCompany = {
  id: 'locosys',
  name: 'Locosys',
  title: 'GPS-модули',
  logo: locosys,
  description: [
    `Компания LOCOSYS Technology Inc., основанная в 2005 году, является ведущим тайваньским производителем компонентов для навигационного оборудования.
    Специализируется на аппаратном и программном обеспечении для систем GNSS, беспроводной связи, беспроводной связи, встраиваемых систем для авионики, автомобилестроении и потребительской электроники.`,
    'LOCOSYS - квалифицированный поставщик для автомобильной промышленности, персональной и судовой навигации. В 2017 году в Китае компания стала ключевым поставщиком в направлении Автомобильное инерциальное счисление .',
  ],

  products: [
    {
      name: 'Антенны',
      imageUrl: locosys_1,
      description: '',
    },
    {
      name: 'Модули синхронизации',
      imageUrl: locosys_2,
      description: '',
    },
    {
      name: 'GPS и GNSS модули',
      imageUrl: locosys_3,
      description: '',
    },
    {
      name: 'RTK модули',
      imageUrl: locosys_4,
      description: '',
    },
  ],
}

const YetnorsonCompany = {
  id: 'yetnorson',
  name: 'Yetnorson',
  title: 'Антенны',
  logo: yetnorson,
  description: [
    `Компания Shenzhen Yetnorson Technology Co., ltd основана в июля 2010 года. Она занимается исследованиями, разработкой, проектированием и производством антенных устройств и аксессуаров.
    За 10 лет производственные площади компании Yetnorson выросли до 5000 квадратных метров, где размещаются современные технологичные станки и производственные линии, тестовые лаборатории, безэховая камера и система ультразвукового контроля.`,
    ' С ростом производства, объемов продаж и проектов компания Shenzhen Yetnorson Technology получила ряд патентов и серьезно занимается вопросами интеллектуальной собственности.',
    `Антенны и кабельные сборки Yetnorson Technology применяются в тахографах, онлайн кассах, терминалах оплаты, автомобильных трекерах и закладках, системах мониторинга транспорта, брелоках, роутерах и другом оборудовании.
     Навигационные антенны Yetnorson Technology поставляются на заводы крупных азиатских автопроизводителей.`,
  ],

  products: [
    {
      name: 'GPS/ГЛОНАСС антенны',
      imageUrl: yetnorson_1,
      description: '',
    },
    {
      name: 'GSM/3G/LTE антенны',
      imageUrl: yetnorson_2,
      description: '',
    },
    {
      name: 'Антенны для диапазонов 433/868/2400МГц',
      imageUrl: yetnorson_3,
      description: '',
    },
    {
      name: 'Комбинированные антенны',
      imageUrl: yetnorson_4,
      description: '',
    },
  ],
}

const FeasycomCompany = {
  id: 'feasycom',
  name: 'Feasycom',
  title: 'Bluetooth- и Wi-Fi-модули',
  logo: feasycom,
  description: [
    'Feasycom занимается исследованиями и разработкой продуктов IoT (интернета вещей). Обладая более чем 10-летним опытом работы в отрасли беспроводной связи, Feasycom обладает огромными возможностями для обеспечения разработки продуктов с низким уровнем риска, снижения затрат на системную интеграцию и сокращения цикла разработки продуктов для тысяч различных клиентов по всему миру.',
    'Feasycom занимается исследованиями и разработкой продуктов и услуг IoT. У компании есть собственные реализации стека Bluetooth и Wi-Fi. Широкая линейка продукции охватывает технологии Bluetooth, Wi-Fi, RFID, 4G, Matter/Thread и UWB.',
  ],
  products: [
    {
      name: 'Bluetooth-модули BLE 5.0-5.2',
      imageUrl: feasycom_1,
      description: '',
    },
    {
      name: 'Dual Mode Bluetooth-модули',
      imageUrl: feasycom_2,
      description: '',
    },
    {
      name: 'Audio Bluetooth-модули',
      imageUrl: feasycom_3,
      description: '',
    },
    {
      name: 'Модули Bluetooth + Wi-Fi',
      imageUrl: feasycom_4,
      description: '',
    },
  ],
}

const NeowayCompany = {
  id: 'neoway',
  name: 'Neoway',
  title: '5G-, 4G и GSM-модули',
  logo: neoway,
  description: [
    'Neoway — высокотехнологичное предприятие, специализирующееся на создании устройств связи для промышленного интернета вещей (IIoT). Лидер китайского рынка в производстве GSM-модулей для рынка приборов учёта. ',
    'Ассортимент Neoway включает в себя сотовые модули и решения 2G/3G/4G/NB-IoT/eMTC, которые широко используются в энергоучёте, телематике, мобильных системах оплаты, безопасности и других отраслях экономики.',
    'Основные преимущества: высокая скорость передача данных, низкая цена, высокая надежность',
    'Основные применения: в системах телеметрии и удаленного управления объектами, мониторинга транспорта, системах охраны и автоматического считывания показаний счетчиков.',
  ],
  products: [
    {
      name: '5G модули',
      imageUrl: neoway_1,
      description: '',
    },
    {
      name: '4G модули',
      imageUrl: neoway_2,
      description: '',
    },
    {
      name: 'GSM-модули',
      imageUrl: neoway_3,
      description: '',
    },
    {
      name: 'NB-IoT-модули',
      imageUrl: neoway_4,
      description: '',
    },
  ],
}

const PRTCompany = {
  id: 'prt',
  name: 'PRT',
  title: 'Термопринтеры',
  logo: prt,
  description: [
    'Xiamen PRT technology Co., Ltd., основанная в 2004 году, является ведущим и профессиональным поставщиком решений для печати в Китае, специализирующимся на исследованиях и разработках, производстве, дистрибуции механизмов термопринтера, панельных принтеров, а также решений для киосков',
    'Продукция HPRT всегда может удовлетворить быстро меняющиеся требования рынка, поскольку у она имеет опытную и инновационную команда разработчиков. PRT предлагаем клиентам продукцию в сочетании с передовыми технологиями, превосходным качеством, конкурентоспособной ценой и беспроблемным обслуживанием.',
  ],
  products: [
    {
      name: 'Механизмы 2 и 3 дюйма',
      imageUrl: prt_1,
      description: '',
    },
    {
      name: 'Механизмы 4,6 и 8 дюймов',
      imageUrl: prt_2,
      description: '',
    },
    {
      name: 'Мобильные принтеры 2 и 3 дюйма',
      imageUrl: prt_3,
      description: '',
    },
    {
      name: 'Мобильные принтеры 4 и 8 дюймов',
      imageUrl: prt_4,
      description: '',
    },
  ],
}

const CJTouchCompany = {
  id: 'cjtouch',
  name: 'CJTouch',
  title: 'Дисплеи и мониторы',
  logo: cjtouch,
  description: [
    'Основана в 2011 году. Ставя интересы клиента на первое место, CJTOUCH неизменно предлагает исключительный клиентский опыт и удовлетворенность благодаря широкому спектру сенсорных технологий и решений, включая сенсорные системы "все в одном".',
    'CJTOUCH предоставляет своим клиентам передовые сенсорные технологии по разумной цене. CJTOUCH дополнительно повышает непревзойденную ценность за счет настройки в соответствии с конкретными потребностями, когда это необходимо. Универсальность сенсорных продуктов CJTOUCH очевидна из-за их присутствия в различных отраслях, таких как игры, киоски, POS-терминалы, банковское дело, HMI, здравоохранение и общественный транспорт.',
  ],
  products: [
    {
      name: 'Компьютеры с сенсорным экраном',
      imageUrl: cjtouch_1,
      description: '',
    },
    {
      name: 'Наружные сенсорные мониторы высокой яркости',
      imageUrl: cjtouch_2,
      description: '',
    },
    {
      name: 'Промышленные сенсорные мониторы',
      imageUrl: cjtouch_3,
      description: '',
    },
    {
      name: 'Инфракрасные сенсорные монитор',
      imageUrl: cjtouch_4,
      description: '',
    },
  ],
}

const HengstarCompany = {
  id: 'hengstar',
  name: 'Hengstar',
  title: 'Дисплеи и мониторы',
  logo: hengstar,
  description: [
    'Основанная в 2004 году, компания Hengstar является мировым поставщиком промышленных решений для LCD панелей управления, LCD мониторов, промышленных ПК, умных дисплеев, цифровых вывесок, длинных ЖК-дисплеев, цифровых досок для конференций и различных LCD продуктов.',
    'Компания Hengstar имеет 15-летний опыт работы в области НИОКР (исследований и разработок) промышленных ЖК-контроллеров, проектирования и производства ЖК-мониторов, промышленных ПК, планшетных ПК на базе Android и других ЖК-решений . Сегодняшний девиз Hengstar – инновации и качество - наша жизнь.',
  ],
  products: [
    {
      name: 'Медицинские мониторы',
      imageUrl: hengstar_1,
      description: '',
    },
    {
      name: 'Промышленные панельные ПК',
      imageUrl: hengstar_2,
      description: '',
    },
    {
      name: 'Наружные цифровые вывески',
      imageUrl: hengstar_3,
      description: '',
    },
    {
      name: 'Водонепроницаемые мониторы',
      imageUrl: hengstar_4,
      description: '',
    },
  ],
}

const KingspecCompany = {
  id: 'kingspec',
  name: 'Kingspec',
  title: 'SSD-накопители',
  logo: kingspec,
  description: [
    'Shenzhen KingSpec Electronics Technology Co., Ltd. была основана в 2007 году. Фабрика KingSpec обладает возможностями в области проектирования и разработки оборудования, производства и оптовой продажи. Правительство Китая ежегодно присуждает KingSpec звание “Национального высокотехнологичного предприятия”.',
    'С 2008 года для компании KingSpec приоритетны инвестиции в разработку технологий твердотельных накопителей и модернизацию производственного процесса. Компания KingSpec сотрудничает с TCL, Great Wall, Emerson, EVOC, AAEON, NEC, SONY, ASUS, Lenovo и другими известными производителями',
  ],
  products: [
    {
      name: 'Промышленные 2.5 дюймовые SSD',
      imageUrl: kingspec_1,
      description: '',
    },
    {
      name: 'PCle 5.0 VP Series',
      imageUrl: kingspec_2,
      description: '',
    },
    {
      name: 'Карты памяти',
      imageUrl: kingspec_3,
      description: '',
    },
    {
      name: 'Портативные SSD',
      imageUrl: kingspec_4,
      description: '',
    },
  ],
}

const AgradeCompany = {
  id: 'agrade',
  name: 'Agrade',
  title: 'SSD-накопители',
  logo: agrade,
  description: [
    'Компания Agrade Storage была основана в 2010 Собственный бренд "Agrade" является основой компании, профессионально занимающейся производством CF/SD-карт, Micro SD-карт, жестких дисков IDE DOM промышленного класса и твердотельных SSD промышленного класса для отечественных и зарубежных заказчиков. ',
    'Компания представляет собой технологическое предприятие, объединяющее независимые разработки, продажи и послепродажное обслуживание. В 2022 году компания Agrade Storage была признана "Национальным высокотехнологичным предприятием").',
  ],
  products: [
    {
      name: 'Промышленные 2.5 дюймовые SSD',
      imageUrl: agrade_1,
      description: '',
    },
    {
      name: 'Промышленные SSD M.2',
      imageUrl: agrade_2,
      description: '',
    },
    {
      name: 'Карты памяти',
      imageUrl: agrade_3,
      description: '',
    },
    {
      name: 'USB DOM',
      imageUrl: agrade_4,
      description: '',
    },
  ],
}

const TaixingCompany = {
  id: 'taixing',
  name: 'Taixing',
  title: 'Разъемы',
  logo: taixing,
  description: [
    'Taixing Aerospace Electronics Co., Ltd. является ключевым системообразующим предприятием, специализирующимся на производстве различных спецификаций и моделей электрических разъемов, в том числе может предложить аналоги разъемов Amphenol.   Высокотехнологичное частное предприятие, занимающееся в основном исследованиями, разработкой и производством высоконадежных оптических соединителей, электрических разъемов и жидкостных соединителей, а также предоставляющее системные решения в области оптических, электрических и жидкостных технологий подключения.',
    'Продукция компании широко используется в различных областях, таких как авиация, аэрокосмическая промышленность, кораблестроение, связь, железные дороги, электроэнергетика, электроника, медицинское обслуживание, железнодорожный транзит, зеленая энергетика.',
  ],
  products: [
    {
      name: 'Разъемы серии GJB2889',
      imageUrl: taixing_1,
      description: '',
    },
    {
      name: 'Разъемы серии JH99',
      imageUrl: taixing_2,
      description: '',
    },
    {
      name: 'Циркулярные соединители',
      imageUrl: taixing_3,
      description: '',
    },
    {
      name: 'Усиленные сетевые разъемы',
      imageUrl: taixing_4,
      description: '',
    },
  ],
}

const SkyMemsCompany = {
  id: 'skyMems',
  name: 'SkyMems',
  title: 'MEMS-акселерометры и гироскопы ',
  logo: skyMems,
  description: [
    'Компания Nanjing Sky MEMS  Technology Co., Ltd. (сокращенно SkyMEMS)– известный производитель инерциального измерительного оборудования, специализирующийся на технологиях измерения и контроля MEMS (Microelectromechanical systems – микроэлектромеханические системы), разработавший и изготовивший инклинометр, электронный компас, MEMS-гироскоп, MEMS-акселерометр, IMU, вертикальный гироскоп, AHRS и интегрированную навигационную систему, оптоволоконный гироскоп и другое.',
    'SkyMEMS имеет сильную команду научных исследований и разработок, в которую входят  ведущие эксперты в области инерциальной продукции, в том числе  в эту команду входят 12 докторов наук и более 30 магистров. Они имеют более чем 10-летний опыт в разработке инерционных продуктов и  систем.SkyMEMS имеет передовое экспериментальное и измерительное оборудование мирового класса, в том числе оборудование для микрообработки, оборудование для микро-нано-испытаний, электрооптическое измерительное оборудование, оборудование для измерения ориентации и оборудование для экспериментов по надежности.',
  ],
  products: [
    {
      name: 'MEMS-акселерометры',
      imageUrl: skyMems_1,
      description: '',
    },
    {
      name: 'MEMS-гироскопы',
      imageUrl: skyMems_2,
      description: '',
    },
    {
      name: 'GNSS/INS',
      imageUrl: skyMems_3,
      description: '',
    },
    {
      name: 'Волоконно-оптические гироскопы',
      imageUrl: skyMems_4,
      description: '',
    },
  ],
}

export const COMPANY_ARRAY = [
  { ...PairuCompany },
  { ...LocosysCompany },
  { ...YetnorsonCompany },
  { ...FeasycomCompany },
  { ...NeowayCompany },
  { ...PRTCompany },
  { ...CJTouchCompany },
  { ...HengstarCompany },
  { ...KingspecCompany },
  { ...AgradeCompany },
  { ...TaixingCompany },
  { ...SkyMemsCompany },
]

export const NEWS_ARRAY = [
  {
    id: 'new-delivery',
    imageUrl: '/static/images/news/news-2.jpeg',
    companyId: PairuCompany.id,
    date: '22.03.2023',
    title: 'Новое поступление товара!',
    shortDescription: 'Пришел новый товар!',
    description: 'Пришел новый товар!',
  },
  {
    id: 'new-delivery',
    imageUrl: '/static/images/news/news-2.jpeg',
    companyId: PairuCompany.id,
    date: '22.03.2023',
    title: 'Новое поступление товара!',
    shortDescription: 'Пришел новый товар!',
    description: 'Пришел новый товар!',
  },
]
